<template>
    <pendo-card
        v-pendo-loading:feather="isFetching"
        :title="chartLabel"
        class="page-feature-usage-over-time"
        data-cy="page-feature-usage-over-time-chart">
        <template #title>
            <div class="page-feature-usage-over-time__title">
                <span>{{ chartLabel }}:</span>
                <pendo-multiselect
                    :value="selectedPeriod"
                    :allow-empty="false"
                    :options="periodOptions"
                    @select="changeSelectedPeriod">
                    <template #trigger>
                        <pendo-data-source-trigger />
                    </template>
                </pendo-multiselect>
                <pendo-multiselect
                    :value="selectedMetric"
                    :allow-empty="false"
                    :options="metricOptions"
                    @select="changeSelectedMetric">
                    <template #trigger>
                        <pendo-data-source-trigger />
                    </template>
                </pendo-multiselect>
            </div>
        </template>
        <template #headerRight>
            <checkbox-multi-select
                class="pages-checkbox-select"
                :options="pageOptions"
                :selected-options="localSelectedPages"
                :search-filter="filterPagesByName"
                entity-type="pages"
                @select="updateChartPageSelections" />
            <pendo-divider
                width="40px"
                direction="vertical" />
            <checkbox-multi-select
                class="features-checkbox-select"
                :options="featureOptions"
                :selected-options="localSelectedFeatures"
                :search-filter="filterFeaturesByName"
                entity-type="features"
                @select="updateChartFeatureSelections" />
        </template>
        <template
            v-if="selectedEntities.length && chart && !isFetching"
            #filters>
            <div data-cy="selected-entities">
                <pendo-tag
                    v-for="entity in selectedEntities"
                    :key="entity.id"
                    :prefix-icon="{
                        type: 'circle',
                        stroke: chart.get(entity.id) && chart.get(entity.id).color,
                        fill: chart.get(entity.id) && chart.get(entity.id).color,
                        size: 12
                    }"
                    :label="entity.displayName"
                    type="filter" />
            </div>
        </template>
        <div class="page-feature-usage-over-time__chart">
            <pendo-empty-state
                v-if="(chartEmpty || chartEmptyByAppFilter) && !isFetching"
                :icon="{ 'type': 'page', 'size': 32, 'stroke-width': 1.5 }"
                :title="emptyChartTitle"
                :description="emptyChartDescription"
                class="page-feature-usage-over-time__empty" />
            <div
                ref="highchartsContainer"
                class="pendo-highcharts-container" />
        </div>
    </pendo-card>
</template>

<script>
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import {
    PendoCard,
    PendoDataSourceTrigger,
    PendoEmptyState,
    PendoLoading,
    PendoDivider,
    PendoMultiselect,
    PendoTag,
    PendoTooltip
} from '@pendo/components';
import { LINE_CHART_BASE, chartColors } from '@/utils/highcharts';
import { pageFeatureUsageTooltipFormatter, validPeriodsForCount } from '@/utils/time-series';
import { filterIsAllApps } from '@/utils/filters';
import CheckboxMultiSelect from '@/components/common/CheckboxMultiSelect.vue';

export default {
    name: 'PageFeatureUsageOverTime',
    components: {
        PendoCard,
        PendoDataSourceTrigger,
        PendoEmptyState,
        PendoMultiselect,
        PendoDivider,
        PendoTag,
        CheckboxMultiSelect
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        activeDateRange: {
            type: Object,
            default: () => ({})
        },
        activeSegmentId: {
            type: String,
            default: null
        },
        activeSubscriptionUtcOffset: {
            type: Number,
            default: 0
        },
        appIdsFilter: {
            type: Array,
            required: true
        },
        featureList: {
            type: Array,
            required: true
        },
        isAppIdsFilterInUse: {
            type: Boolean,
            default: false
        },
        isFetchingFeatureList: {
            type: Boolean,
            default: false
        },
        isFetchingPageFeatureUsageOverTime: {
            type: Boolean,
            default: false
        },
        isFetchingPageList: {
            type: Boolean,
            default: false
        },
        pageList: {
            type: Array,
            required: true
        },
        pageUsageChart: {
            type: Object,
            default: () => ({})
        },
        usageOverTime: {
            type: Object,
            default: () => ({
                pageUsageOverTime: [],
                featureUsageOverTime: []
            })
        },
        engageChartColors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null,
            chartConfig: null,
            selectedPeriod: null,
            selectedMetric: null
        };
    },
    computed: {
        chartLabel () {
            return 'Page & Feature Usage Over Time';
        },
        emptyChartTitle () {
            if (this.chartEmptyByAppFilter) {
                return 'Selected Pages and Features do not match Application Filter';
            }

            return 'No Pages or Features Selected';
        },
        emptyChartDescription () {
            if (this.chartEmptyByAppFilter) {
                return 'Try updating or clearing the application filter to see usage data.';
            }

            return 'Add pages or features to track their usage over time.';
        },
        chartEmpty () {
            return !this.localSelectedPages.length && !this.localSelectedFeatures.length;
        },
        chartEmptyByAppFilter () {
            if (this.chartEmpty) return false;

            return !this.selectedEntities.length;
        },
        isFetching () {
            const isFetchingEntities = this.isFetchingPageList || this.isFetchingFeatureList;

            return isFetchingEntities || this.isFetchingPageFeatureUsageOverTime;
        },
        selectedEntities () {
            const featuresForAppIdsFilter = this.filterEntitiesByAppIds(this.localSelectedFeatures, this.appIdsFilter);
            const pagesForAppIdsFilter = this.filterEntitiesByAppIds(this.localSelectedPages, this.appIdsFilter);

            return [...pagesForAppIdsFilter, ...featuresForAppIdsFilter];
        },
        savedPageUsageIds () {
            return get(this.pageUsageChart, 'pageIds', []);
        },
        savedFeatureUsageIds () {
            return get(this.pageUsageChart, 'featureIds', []);
        },
        pageOptions () {
            const pageOptions = this.pageList.map((page) => this.buildPageFeatureOption(page));

            if (!this.isAppIdsFilterInUse) return pageOptions;

            const pagesForSelectedApps = this.filterEntitiesByAppIds(pageOptions, this.appIdsFilter);

            return pagesForSelectedApps;
        },
        featureOptions () {
            const featureOptions = this.featureList.map((feature) => this.buildPageFeatureOption(feature));

            if (!this.isAppIdsFilterInUse) return featureOptions;

            const featuresForSelectedApps = this.filterEntitiesByAppIds(featureOptions, this.appIdsFilter);

            return featuresForSelectedApps;
        },
        periodOptions () {
            const periods = validPeriodsForCount(this.activeDateRange.count);

            return periods.map((id) => {
                return {
                    label: capitalize(id),
                    id
                };
            });
        },
        metricOptions () {
            const options = [
                {
                    label: 'Visitors',
                    id: 'visitors'
                }
            ];

            const viewsOption = {
                label: 'Views / Clicks',
                id: 'views'
            };

            return [viewsOption, ...options];
        },
        pageSeriesData () {
            if (!this.localSelectedPages.length) return [];

            return this.pageUsageOverTime.map((pageData, index) => {
                const data = pageData.map(({ value, timestamp }) => {
                    return {
                        x: timestamp,
                        y: value,
                        entityType: 'page'
                    };
                });

                return {
                    id: this.localSelectedPages[index].id,
                    name: this.localSelectedPages[index].displayName,
                    entityType: 'page',
                    data,
                    showInLegend: false,
                    marker: { enabled: false, symbol: 'circle' }
                };
            });
        },
        featureSeriesData () {
            if (!this.localSelectedFeatures.length) return [];

            return this.featureUsageOverTime.map((featureData, index) => {
                const data = featureData.map(({ value, timestamp }) => {
                    return {
                        x: timestamp,
                        y: value,
                        entityType: 'feature'
                    };
                });

                return {
                    id: this.localSelectedFeatures[index].id,
                    name: this.localSelectedFeatures[index].displayName,
                    data,
                    showInLegend: false,
                    marker: { enabled: false, symbol: 'circle' }
                };
            });
        },
        chartSeries () {
            return [...this.pageSeriesData, ...this.featureSeriesData];
        },
        yAxisLabel () {
            const metric = this.selectedMetric || this.metricOptions[0];

            return metric.label;
        },
        xDateFormat () {
            switch (this.selectedPeriod) {
                case 'monthly':
                    return 'Month of %b %d';
                case 'weekly':
                    return 'Week of %b %d';
                default:
                    return '%b %d';
            }
        },
        localSelectedFeatures () {
            const features = this.savedFeatureUsageIds.reduce((acc, featureId) => {
                const feature = this.featureList.find((feature) => feature.id === featureId);
                if (!feature) return acc;

                const featureOption = this.buildPageFeatureOption(feature);
                acc.push(featureOption);

                return acc;
            }, []);

            return features;
        },
        localSelectedPages () {
            const pages = this.savedPageUsageIds.reduce((acc, pageId) => {
                const page = this.pageList.find((page) => page.id === pageId);
                if (!page) return acc;

                const pageOption = this.buildPageFeatureOption(page);

                acc.push(pageOption);

                return acc;
            }, []);

            return pages;
        },
        pageUsageOverTime () {
            return this.usageOverTime.pageUsageOverTime;
        },
        featureUsageOverTime () {
            return this.usageOverTime.featureUsageOverTime;
        },
        platformChartColors () {
            if (this.engageChartColors.length) {
                return this.engageChartColors;
            }

            return chartColors;
        }
    },
    watch: {
        activeDateRange () {
            this.changeSelectedPeriod();
        },
        appIdsFilter () {
            this.changeSelectedPeriod();
        },
        activeSegmentId () {
            this.changeSelectedPeriod();
        },
        localSelectedFeatures () {
            this.refreshChartSelections();
        },
        localSelectedPages () {
            this.refreshChartSelections();
        },
        usageOverTime () {
            this.populateChartWithNewData();
        }
    },
    mounted () {
        this.selectedMetric = this.metricOptions[0];

        this.changeSelectedPeriod();

        this.chartConfig = this.getChartConfig(this.$pendo.highcharts);
        if (this.$refs.highchartsContainer) {
            this.chart = this.$pendo.highcharts.chart(this.$refs.highchartsContainer, this.chartConfig);
        }
    },
    methods: {
        filterFeaturesByName (features, searchString) {
            return features.filter((feature) => {
                return feature.displayName.toLowerCase().includes(searchString.toLowerCase());
            });
        },
        buildPageFeatureOption (entity) {
            let { displayName } = entity;

            if (entity.app) {
                displayName = `${entity.app.displayName} > ${entity.displayName}`;
            }

            return {
                displayName,
                id: entity.id,
                app: entity.app,
                appId: entity.appId
            };
        },
        changeSelectedPeriod (selectedPeriod) {
            const selectedPeriodId = get(selectedPeriod, 'id');
            const validPeriodOption = this.periodOptions.find((period) => period.id === selectedPeriodId);
            const timePeriod = validPeriodOption || this.periodOptions[0];

            this.selectedPeriod = timePeriod;
            this.refreshChartSelections();
        },
        changeSelectedMetric (selectedMetric) {
            this.selectedMetric = selectedMetric;
            this.refreshChartSelections();
        },
        getChartConfig (highcharts) {
            const vm = this;
            const config = { ...LINE_CHART_BASE };

            config.series = this.chartSeries;
            config.tooltip = {
                shared: true,
                useHTML: true,
                className: 'page-feature-usage-over-time-tooltip',
                formatter () {
                    return pageFeatureUsageTooltipFormatter.call(
                        this,
                        vm.xDateFormat,
                        vm.selectedMetric,
                        highcharts.dateFormat
                    );
                },
                borderColor: '#2A2C35'
            };
            config.chart.height = 316;
            config.plotOptions = {
                line: {
                    softThreshold: false
                }
            };
            config.yAxis[0].title.text = this.yAxisLabel;
            config.colors = this.platformChartColors;
            config.time = {
                timezoneOffset: this.activeSubscriptionUtcOffset
            };

            return config;
        },
        populateChartWithNewData () {
            if (this.isFetchingPageFeatureUsageOverTime || !this.chart) {
                return;
            }

            // handle dynamic addition/removal of plot lines
            while (this.chart.series.length > 0) {
                this.chart.series[0].remove(true);
            }

            this.chartSeries.forEach((newData) => {
                this.chart.addSeries({ data: newData });
            });

            const { xAxis, yAxis } = this.getChartConfig(this.$pendo.highcharts);

            this.chart.update({
                xAxis,
                yAxis,
                series: this.chartSeries,
                plotOptions: {
                    series: {
                        tooltip: { xDateFormat: this.xDateFormat }
                    }
                }
            });
        },
        updateChartFeatureSelections (selectedFeatures) {
            const hasChanged = this.hasSelectionChanged(selectedFeatures, this.localSelectedFeatures);

            if (!hasChanged) return;

            const featureIds = selectedFeatures.map((feature) => feature.id);
            const pageIds = this.localSelectedPages.map((page) => page.id);
            this.updateUserSettingsIfChanged({ pageIds, featureIds });
            this.$emit('setPageUsageChart', { pageUsageChart: { pageIds, featureIds } });
            this.refreshChartSelections();
        },
        updateChartPageSelections (selectedPages) {
            const hasChanged = this.hasSelectionChanged(selectedPages, this.localSelectedPages);

            if (!hasChanged) return;

            const pageIds = selectedPages.map((page) => page.id);
            const featureIds = this.localSelectedFeatures.map((feature) => feature.id);
            this.updateUserSettingsIfChanged({ pageIds, featureIds });
            this.$emit('setPageUsageChart', { pageUsageChart: { pageIds, featureIds } });
            this.refreshChartSelections();
        },
        hasSelectionChanged (originalSelection, newSelection) {
            return !isEqual(sortBy(originalSelection, 'id'), sortBy(newSelection, 'id'));
        },
        refreshChartSelections () {
            const features = this.filterEntitiesByAppIds(this.localSelectedFeatures, this.appIdsFilter);
            const pages = this.filterEntitiesByAppIds(this.localSelectedPages, this.appIdsFilter);
            this.$emit('configChange', {
                chartPages: pages,
                chartFeatures: features,
                usageMetric: this.selectedMetric.id,
                timePeriod: this.selectedPeriod.id
            });
        },
        updateUserSettingsIfChanged ({ pageIds, featureIds }) {
            const samePageIds = isEqual(pageIds.sort(), this.savedPageUsageIds.slice().sort());
            const sameFeatureIds = isEqual(featureIds.sort(), this.savedFeatureUsageIds.slice().sort());

            if (samePageIds && sameFeatureIds) return;

            const userSettings = {
                name: 'pageUsageChart',
                value: JSON.stringify({ pageIds, featureIds })
            };

            this.$emit('updateSubNamespaceSetting', userSettings);
        },
        filterEntitiesByAppIds (entityList = [], appIdFilter = []) {
            if (filterIsAllApps(appIdFilter)) return entityList;

            return entityList.filter((entity) => appIdFilter.includes(entity.appId));
        },
        filterPagesByName (pages, searchString) {
            const formattedSearchString = searchString.toLowerCase();

            return pages.filter((page) => {
                return page.displayName.toLowerCase().includes(formattedSearchString);
            });
        }
    }
};
</script>
<style lang="scss">
.page-feature-usage-over-time-tooltip {
    z-index: 1;
}

.page-feature-usage-over-time {
    overflow-y: visible;
    z-index: 1;

    &__title {
        .pendo-multiselect {
            margin-left: 8px;
        }
    }

    &__chart {
        position: relative;
    }

    &__empty {
        margin-top: 16px;
        margin-bottom: 24px;
        z-index: 1;
    }

    .pendo-card__filters {
        .pendo-tag,
        .pendo-tag + .pendo-tag {
            margin: 0px 8px 8px 0px;
        }
    }

    &__modal {
        .pendo-card__body {
            padding: 0;
        }

        &__body {
            display: flex;
            flex-direction: column;

            &--description {
                margin-top: 0;
                margin-bottom: 4px;
            }

            &--row {
                display: flex;
                margin-bottom: 28px;

                &-existing {
                    flex-grow: 1;
                    margin-right: 18px;
                }

                &-new {
                    flex-grow: 1;
                    margin-right: 36px;
                }
            }
        }
    }
}
</style>
