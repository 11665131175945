<template>
    <pendo-layout-chooser
        :default-layouts="defaultLayouts"
        :loading="isLoading"
        :apps="showMobileAppsOption"
        :selected-app="selectedAppForLayoutChooser"
        :use-app-display="true"
        @select-layout="chooseLayout"
        @select-app="chooseApp"
        @close="close" />
</template>

<script>
import getDefaultLayouts, {
    _PENDO_DEFLYT_MobileCarouselSecondStep, // eslint-disable-line camelcase
    _PENDO_DEFLYT_MobileCarouselThirdStep // eslint-disable-line camelcase
    } from '@/utils/default-layouts';
import { BuildingBlock, BuildingBlockLayouts, BuildingBlockProperties, SdkTools } from '@pendo/services/BuildingBlocks';
import { mapGetters, mapActions } from 'vuex';
import { PendoLayoutChooser } from '@pendo/components';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import isUndefined from 'lodash/isUndefined';
import semver from 'semver';
import carouselThumbnail from '@/img/mobile-carousel-thumbnail.png';
import { isMobileTooltipGuide } from '@/stateless-components/utils/guides';

const { v4: uuid } = require('uuid');

const { getMinSdkVersionForBuildingBlocks, isFutureSdkLayout, MIN_FLUTTER_CODELESS_SDK_VERSION } = SdkTools;

export default {
    name: 'LayoutChooser',
    components: {
        PendoLayoutChooser
    },
    data () {
        return {
            isLoading: true,
            selectedAppForLayoutChooser: null,
            defaultLayouts: [],
            appliedTheme: {},
            carouselLayouts: {
                _PENDO_DEFLYT_MobileCarouselSecondStep,
                _PENDO_DEFLYT_MobileCarouselThirdStep
            }
        };
    },
    computed: {
        ...mapGetters({
            productName: 'subscriptions/productName',
            getThemeByAppId: 'themes/getThemeByAppId',
            getThemeById: 'themes/getThemeById',
            list: 'layouts/list',
            webLayoutsWithoutCodeBlocks: 'layouts/webLayoutsWithoutCodeBlocks',
            mobileLayoutsWithoutCodeBlocks: 'layouts/mobileLayoutsWithoutCodeBlocks',
            isRestrictedMobileApp: 'apps/isRestrictedMobileApp',
            mobileLayouts: 'layouts/mobileLayouts',
            excludeMobileForActiveSubscription: 'apps/excludeMobileForActiveSubscription',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        selectedAppId () {
            return get(this.selectedAppForLayoutChooser, 'id');
        },
        defaultThemeByApp () {
            return this.getThemeByAppId(this.selectedAppId);
        },
        isAppFlutterCodeless () {
            return (
                this.selectedAppForLayoutChooser?.framework === 'flutter' &&
                !this.isRestrictedMobileApp(this.selectedAppId)
            );
        },
        selectedAppPlatform () {
            const platform = get(this.selectedAppForLayoutChooser, 'platform', 'web');

            return platform === 'extension' ? 'web' : platform;
        },
        showMobileAppsOption () {
            return this.hasSegmentFlag('teamWisteria')
                ? this.listAllForActiveSubscription
                : this.excludeMobileForActiveSubscription;
        }
    },
    async created () {
        await this.fetchThemes({ noCache: true });
        await this.fetchLayouts({ noCache: true });
        this.defaultLayouts = await this.buildLayoutsAsync();

        this.resetLoading();
    },
    methods: {
        ...mapActions({
            fetchLayouts: 'layouts/fetchLayouts',
            fetchThemes: 'themes/fetchAll'
        }),
        async resetLoading () {
            await this.$nextTick();
            setTimeout(() => {
                this.isLoading = false;
                if (window.pendo) window.pendo.flexElement(window.document);
            }, 250);
        },
        createPreview (dom) {
            const buildNodeFromJSON = get(window, 'pendo.buildNodeFromJSON');
            const createBBTooltip = get(window, 'pendo.BuildingBlocks.BuildingBlockTooltips.createBBTooltip');

            return BuildingBlock.generatePreviewHTML(buildNodeFromJSON, createBBTooltip, dom);
        },
        chooseLayout (layout) {
            if (this.isCarouselLayout(layout)) {
                const carouselId = uuid().substring(0, 8);

                const layouts = [
                    _PENDO_DEFLYT_MobileCarouselSecondStep, // eslint-disable-line camelcase
                    _PENDO_DEFLYT_MobileCarouselThirdStep // eslint-disable-line camelcase
                ].map((id) => this.list.find(({ id: layoutId }) => layoutId === id));

                layouts.forEach((layoutItem) => {
                    BuildingBlock.applyThemeToBuildingBlocks({
                        theme: this.appliedTheme,
                        buildingBlocks: layoutItem.buildingBlocks
                    });
                    layoutItem.carouselId = carouselId;
                });

                layout.carouselId = carouselId;
                layout.buildingBlocks = [
                    layout.buildingBlocks,
                    ...layouts.map((layoutItem) => layoutItem.buildingBlocks)
                ];
            }

            this.$emit('onChooseLayout', layout, this.selectedAppId, this.isAppFlutterCodeless);
        },
        async chooseApp (app) {
            this.isLoading = true;
            this.selectedAppForLayoutChooser = app;
            this.defaultLayouts = await this.buildLayoutsAsync();
            this.resetLoading();
        },
        close () {
            this.$emit('onClose');
        },
        isCarouselLayout (layout) {
            const buildingBlocks = get(layout, 'buildingBlocks');

            return !isUndefined(
                BuildingBlock.findBlockByWidgetId(buildingBlocks, BuildingBlockLayouts.widgetIds.carousel)
            );
        },
        isMobileTooltip (buildingBlocks) {
            if (!this.isMobileApp) return;

            return isMobileTooltipGuide(buildingBlocks);
        },
        isNumberScalePoll (buildingBlocks) {
            return !!BuildingBlock.findBlockByWidgetId(buildingBlocks, BuildingBlockLayouts.widgetIds.numberScaleGroup);
        },
        buildLayoutsAsync () {
            return new Promise((resolve) => {
                if (!this.webLayoutsWithoutCodeBlocks && !this.mobileLayoutsWithoutCodeBlocks) return [];

                const isWebApp = this.selectedAppPlatform === 'web';
                const isMobileApp = this.selectedAppPlatform === 'ios' || this.selectedAppPlatform === 'android';

                const mobileLayouts = getDefaultLayouts(
                    this.defaultThemeByApp,
                    this.selectedAppPlatform,
                    'mobile'
                ).concat(this.mobileLayoutsWithoutCodeBlocks);
                const allWebLayouts = getDefaultLayouts(this.defaultThemeByApp, this.selectedAppPlatform, 'web').concat(
                    this.webLayoutsWithoutCodeBlocks
                );

                const platformSelectedLayouts = isWebApp ? allWebLayouts : mobileLayouts;

                const subtext = this.productName ? `${this.productName} Default` : 'Pendo Default';
                const layouts = platformSelectedLayouts.reduce((layouts, layout, index) => {
                    // save aside mobile carousel 2nd and 3rd steps as we want to use them when choosing
                    // a carousel layout without showing them as separate layouts
                    if (this.carouselLayouts[layout.id]) {
                        this.carouselLayouts[layout.id] = layout;

                        return layouts;
                    }

                    if (isMobileApp) {
                        const minSdkVersion = getMinSdkVersionForBuildingBlocks(layout.buildingBlocks);
                        if (semver.satisfies(minSdkVersion, '>2.4.0')) {
                            layout.minSdkVersion = minSdkVersion;
                        }
                    }

                    const isPendoDefaultLayout = get(layout.lastUpdatedByUser, 'username') === '--system-user--';
                    const localTheme = isPendoDefaultLayout
                        ? this.defaultThemeByApp
                        : this.getThemeById(layout.themeId);

                    this.appliedTheme = localTheme;

                    const { tags = [], ...rest } = layout;
                    if (tags.includes('isAnnouncement')) return layouts;

                    let buildingBlocks = cloneDeep(
                        isArray(layout.buildingBlocks) ? layout.buildingBlocks[0] : layout.buildingBlocks
                    );

                    if (this.isMobileTooltip(buildingBlocks)) {
                        // filter out tooltip layouts for non codeless apps
                        if (this.isRestrictedMobileApp(this.selectedAppId)) {
                            return layouts;
                        }
                        if (
                            this.isAppFlutterCodeless &&
                            (!layout.minSdkVersion || semver.lt(layout.minSdkVersion, MIN_FLUTTER_CODELESS_SDK_VERSION))
                        ) {
                            layout.minSdkVersion = MIN_FLUTTER_CODELESS_SDK_VERSION;
                        }
                    }

                    // filter out default layouts that include not-yet-released features
                    if (isMobileApp && layout.default && isFutureSdkLayout(layout)) {
                        return layouts;
                    }

                    if (isMobileApp && this.isCarouselLayout(layout) && layout.default) {
                        layout.readOnly = true;
                    }

                    // remove layout_weight for number scale poll label - on ios app only
                    if (this.selectedAppPlatform === 'ios' && this.isNumberScalePoll(buildingBlocks)) {
                        const nspLabels = BuildingBlock.findBlocksByDomId(
                            layout.buildingBlocks,
                            'pendo-mobile-nsp-label'
                        );

                        nspLabels.forEach((labelBlock) =>
                            BuildingBlockProperties.deletePropertyByname(labelBlock.properties, 'layout_weight')
                        );
                        const rowDomIds = nspLabels.map((labelBlock) => labelBlock.web.rowDomId);
                        const uniquRowDomIds = [...new Set(rowDomIds)];
                        uniquRowDomIds.forEach((rowDomId) => {
                            const rowBlock = BuildingBlock.findBlockByDomId(layout.buildingBlocks, rowDomId);
                            BuildingBlockProperties.deletePropertyByname(rowBlock.properties, 'weightSum');
                        });
                    }

                    const containerBlock = BuildingBlock.findBlockByDomId(buildingBlocks, 'pendo-guide-container');
                    const hasCustomTheme =
                        !!containerBlock &&
                        (!containerBlock.web.themeStyle || containerBlock.web.themeStyle === 'custom');
                    buildingBlocks = BuildingBlock.applyThemeToBuildingBlocks({
                        theme: localTheme,
                        buildingBlocks
                    });

                    const dom = BuildingBlock.buildingBlocksToDom(buildingBlocks);

                    layout.previewHTML = this.createPreview(dom);

                    // mobile carousel guide should have a unique thumbnail
                    if (this.isCarouselLayout(layout)) {
                        layout.previewHTML = `
                        <div style="width: 126px; height: 112px; display: flex">
                            <img src='${carouselThumbnail}' alt=''/>
                        </div>
                        `;
                    }

                    layouts.push({
                        ...rest,
                        id: rest.id || index,
                        active: true,
                        buildingBlocks,
                        previewHTML: layout.previewHTML,
                        hasCustomTheme,
                        subtext,
                        minSdkVersion: layout.minSdkVersion,
                        readOnly: layout.readOnly
                    });

                    return layouts;
                }, []);

                resolve(this.sortByName(layouts));
            });
        },
        sortByName (layouts) {
            layouts.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            });

            return layouts;
        }
    }
};
</script>

<style lang="scss">
.pendo-layout-chooser {
    .pendo-media-card__subtitle > div {
        @include ellipsis;
    }
}

.custom-layout-note {
    padding-top: 14px;
    padding-left: 24px;
    padding-right: 24px;
}

.layout-chooser-empty-state {
    height: calc(100vh - 230px);

    .pendo-empty-state {
        position: relative;
    }

    .pendo-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.pendo-layout-chooser {
    &__app-theme-card {
        margin-top: -40px;
        margin-bottom: 30px !important; /* stylelint-disable-line */
    }
}
</style>
