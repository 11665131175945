/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request, parseSegmentIdForAggregation, identifiedState } from '@/utils/aggregations';

export function getPollResponseCount ({ appIds, timeSeries, guideId, pollId, segmentId }) {
    const spec = buildPollResponseCountSpec({ appIds, timeSeries, guideId, pollId, segmentId });

    return request(spec);
}

export function buildPollResponseCountSpec ({ appIds, timeSeries, guideId, pollId, segmentId }) {
    const { operators: o, common: c } = Agg2;

    const internalTimeSeries = {
        ...timeSeries,
        period: 'dayRange'
    };

    return o.aggregation(
        'poll-response-count',
        o.pipeline(
            o.sources.pollEvents({ appId: appIds, timeSeries: internalTimeSeries, guideId, pollId }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                ['pollResponse'],
                ...c.groupFields({
                    count: o.count(null)
                })
            )
        )
    );
}

export function getPollResponseList ({ appIds, timeSeries, guideId, pollId, segmentId, pollResponseMap }) {
    const spec = buildPollResponseListSpec({ appIds, timeSeries, guideId, pollId, segmentId, pollResponseMap });

    return request(spec);
}

export function buildPollResponseListSpec ({ appIds, timeSeries, guideId, pollId, segmentId, pollResponseMap }) {
    const { operators: o } = Agg2;

    const internalTimeSeries = {
        ...timeSeries,
        period: 'dayRange'
    };

    return o.aggregation(
        'poll-response-list',
        o.pipeline(
            o.sources.pollEvents({ appId: appIds, timeSeries: internalTimeSeries, guideId, pollId }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            pollResponseMap && o.switchStatement('mappedPollResponse', 'pollResponse', ...pollResponseMap),
            o.select({
                visitorId: 'visitorId',
                browserTime: 'browserTime',
                pollResponse: pollResponseMap ? 'mappedPollResponse' : 'pollResponse'
            }),
            o.bulkExpand('visitor')
        )
    );
}
