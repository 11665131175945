import { operators as o } from '@pendo/aggregations';
import { parseSegmentIdForAggregation, identifiedState } from '@/utils/aggregations';

export default {
    name: 'GuidePollResponseCount',
    responseFormat: 'rows',
    build: ({ guideId, timeSeries, segmentId }) => {
        return o.pipeline(
            o.sources.pollEvents({
                guideId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(['pollId'], o.groupField('count', o.count('visitorId')))
        );
    }
};
