import sortBy from 'lodash/sortBy';

export const guideSchema = {
    kind: 'visitor',
    schema: 'guide',
    name: 'Guide Views',
    id: 'guide',
    type: 'guide'
};

export const guideElementSchema = {
    kind: 'visitor',
    schema: 'guideElement',
    name: 'Guide Element',
    id: 'guideElement',
    type: 'guideElement'
};

export const resourceCenterSchema = {
    kind: 'visitor',
    schema: 'guide',
    name: 'Resource Center Views',
    id: 'resourceCenter',
    type: 'guide'
};

export const pollSchema = {
    kind: 'visitor',
    schema: 'poll',
    name: 'Poll Responses',
    id: 'poll',
    type: 'poll'
};

export const pageSchema = {
    kind: 'visitor',
    schema: 'page',
    name: 'Page Views',
    id: 'page',
    type: 'page'
};

export const featureSchema = {
    kind: 'visitor',
    schema: 'feature',
    name: 'Feature Clicks',
    id: 'feature',
    type: 'feature'
};

export const applicationSchema = {
    kind: 'visitor',
    schema: 'application',
    name: 'Application Usage',
    id: 'application',
    type: 'application'
};

export const segmentSchema = {
    kind: 'segment',
    schema: 'segment',
    name: 'Segment',
    id: 'segment',
    type: 'segment'
};

export const workflowSchema = {
    kind: 'visitor',
    schema: 'workflow',
    name: 'Workflow Progress',
    id: 'workflow',
    type: 'workflow'
};

export function reduceSchema (data, kind) {
    return Object.entries(data).reduce((list, [group, groupData]) => {
        // this is easier to filter on the frontend than the backend
        delete groupData.accountid;
        delete groupData.accountids;
        delete groupData.lastservername;
        delete groupData.lastbrowserversion;
        //

        const schema = Object.entries(groupData).map(([field, values]) => ({
            group,
            kind,
            schema: values.Type,
            elementType: values.ElementType,
            name: values.DisplayName || field,
            id: `${kind}_${group}_${field}`,
            field: `${kind}.${group}.${field}`,
            type: `metadata.${group}.${field}`,
            neverIndex: values.neverIndex
        }));

        return list.concat(schema);
    }, sortBy([applicationSchema, featureSchema, guideSchema, guideElementSchema, pageSchema, pollSchema, resourceCenterSchema, segmentSchema, workflowSchema], 'name'));
}
