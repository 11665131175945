<template>
    <div
        v-pendo-loading:material="loading"
        class="poll-activity-chart">
        <div
            ref="poll-activity"
            class="pendo-highcharts-container" />
    </div>
</template>

<script>
import { PendoLoading } from '@pendo/components';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export default {
    name: 'PollActivityChart',
    directives: {
        PendoLoading
    },
    props: {
        poll: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        responseCounts: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null,
            chartConfig: {}
        };
    },
    computed: {
        pollType () {
            return get(this.poll, 'attributes.type');
        },
        series () {
            const lookup = keyBy(this.responseCounts, 'pollResponse');

            return [
                {
                    name: 'Responses',
                    data: this.categories.map((category) => {
                        return get(lookup, `['${category}'].count`, 0);
                    })
                }
            ];
        },
        isNumericRating () {
            return ['NumberScale', 'NPSRating'].includes(this.pollType);
        },
        categories () {
            if (this.isNumericRating) {
                return this.poll.numericResponses;
            }

            return Object.keys(this.poll.idResponses);
        }
    },
    watch: {
        responseCounts () {
            this.chartConfig = this.getChartConfig();
            if (this.$refs['poll-activity']) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['poll-activity'], this.chartConfig);
            }
        }
    },
    methods: {
        getChartConfig () {
            const vm = this;

            return {
                series: this.series,
                chart: {
                    type: 'bar',
                    height: 65 * this.categories.length,
                    spacing: [0, 0, 0, 0]
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                xAxis: {
                    categories: this.categories,
                    crosshair: false,
                    labels: {
                        formatter () {
                            if (get(vm, 'isNumericRating')) {
                                return this.value;
                            }

                            return vm.poll.idResponses[this.value];
                        },
                        y: null,
                        align: 'center'
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                    min: 0,
                    allowDecimals: false,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                tooltip: {
                    enabled: false
                },
                colors: ['#229CA8']
            };
        }
    }
};
</script>

<style lang="scss">
.poll-activity-chart {
    padding: 16px;

    .pendo-highcharts-container {
        min-height: 130px;
    }
}
</style>
