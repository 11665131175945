<template>
    <div>
        <workflows-loading-indicator
            v-if="loading"
            :loading="loading" />
        <no-journeys-found-empty-state
            v-else-if="showEmptyState || savedJourneyHasTooManySelectedPagesFeatures"
            :saved-journey-has-too-many-selected-pages-features="savedJourneyHasTooManySelectedPagesFeatures"
            :no-completions="noWorkflowCompletions"
            @showSelectPagesFeaturesBlade="$emit('showSelectPagesFeaturesBlade')" />
        <pendo-card
            v-else
            class="journeys-card"
            :title="`Completed workflow journeys (${completedJourneysCount})`">
            <template #body>
                <div class="journeys-card__tabs">
                    <div class="journeys-card__tabs--buttons">
                        <pendo-radio-group
                            v-model="currentTab"
                            :button="true">
                            <pendo-radio-button
                                v-for="tab in tabs"
                                :key="tab.value"
                                :selected="currentTab === tab.value"
                                v-bind="tab"
                                @change="$emit('hideInsights')" />
                        </pendo-radio-group>
                    </div>
                    <div
                        v-if="currentTab === 'allJourneys'"
                        class="journeys-card__tabs--filter">
                        <pendo-multiselect
                            :value="selectedSort"
                            :options="sortOptions"
                            :allow-empty="false"
                            :append-to-body="false"
                            placeholder="Select a Filter"
                            :min-trigger-width="250"
                            @input="handleSortSelection">
                            <template #selectedLabel>
                                <div class="sort-select--label">
                                    <pendo-icon
                                        type="chart-bar"
                                        size="14" />
                                    <span>
                                        {{ selectedSort.label }}
                                    </span>
                                </div>
                            </template>
                        </pendo-multiselect>
                    </div>
                </div>
                <div id="journey-card-content">
                    <pendo-loading-indicator
                        v-if="updating"
                        size="large" />
                    <template v-if="currentTab === 'topJourneys'">
                        <top-journeys
                            v-show="!updating"
                            key="top-journeys"
                            :insights-showing="insightsShowing"
                            :included-steps="includedSteps"
                            :journey-data="filteredJourneyData"
                            :show-default-icon="showDefaultIcon"
                            :is-engage="isEngage"
                            @toggleIncludedStep="$emit('toggleIncludedStep', $event)"
                            @toggleInsights="$emit('toggleInsights')"
                            @hideInsights="$emit('hideInsights')" />
                    </template>
                    <template v-if="currentTab === 'allJourneys'">
                        <all-journeys
                            v-show="!updating"
                            key="all-journeys"
                            :updating="updating"
                            :journey-data="filteredJourneyData"
                            :included-steps="includedSteps"
                            :sort-by="selectedSort.name"
                            :show-default-icon="showDefaultIcon"
                            :journey-canvas-wrapper="$refs.journeyCanvasWrapper"
                            :is-engage="isEngage"
                            @toggleIncludedStep="$emit('toggleIncludedStep', $event)" />
                    </template>
                </div>
            </template>
        </pendo-card>
    </div>
</template>

<script>
import {
    PendoCard,
    PendoRadioButton,
    PendoRadioGroup,
    PendoMultiselect,
    PendoIcon,
    PendoLoadingIndicator
} from '@pendo/components';
import WorkflowsLoadingIndicator from '@/components/workflows/details/WorkflowsLoadingIndicator';
import TopJourneys from '@/stateless-components/workflows/journeys/TopJourneys';
import AllJourneys from '@/stateless-components/workflows/journeys/AllJourneys';
import NoJourneysFoundEmptyState from '@/stateless-components/workflows/journeys/NoJourneysFoundEmptyState';
import get from 'lodash/get';

export default {
    name: 'JourneysContent',
    components: {
        TopJourneys,
        AllJourneys,
        PendoCard,
        PendoRadioButton,
        PendoRadioGroup,
        PendoMultiselect,
        PendoIcon,
        PendoLoadingIndicator,
        NoJourneysFoundEmptyState,
        WorkflowsLoadingIndicator
    },
    props: {
        journeyData: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: () => false
        },
        includedSteps: {
            type: Object,
            default: () => ({})
        },
        insightsShowing: {
            type: Boolean,
            default: false
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        isEngage: {
            type: Boolean,
            default: false
        },
        savedJourneyHasTooManySelectedPagesFeatures: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const sortOptions = [
            {
                label: 'Sort by frequency',
                name: 'dispFrequency',
                id: 'frequency'
            },
            {
                label: 'Sort by completion time',
                name: 'dispAvgTime',
                id: 'time'
            },
            {
                label: 'Sort by number of steps',
                name: 'dispNumOfSteps',
                id: 'steps'
            }
        ];

        return {
            homeTabSelector: null,
            currentTab: 'topJourneys',
            selectedSort: sortOptions[0],
            sortOptions,
            updating: false,
            tabs: [
                {
                    value: 'topJourneys',
                    label: 'Top journeys'
                },
                {
                    value: 'allJourneys',
                    label: 'All journeys'
                }
            ]
        };
    },
    computed: {
        completedJourneysCount () {
            return get(this, 'filteredJourneyData.length', 0);
        },
        noWorkflowCompletions () {
            return this.journeyData.length === 0;
        },
        noMatchingMustIncludeJourneys () {
            return this.completedJourneysCount === 0 && Object.values(this.includedSteps).length > 0;
        },
        showEmptyState () {
            return this.noWorkflowCompletions || this.noMatchingMustIncludeJourneys;
        },
        filteredJourneyData () {
            const numOfInclSteps = Object.keys(this.includedSteps).length;
            if (numOfInclSteps) {
                const filteredJourneyData = this.journeyData.filter((row) => {
                    const filteredJourneys = row.journey.filter((journey) => {
                        if (journey.duplicateTags) {
                            journey.duplicateTags.some(
                                (tag) => this.includedSteps[tag.pageId ? 'pageId' : 'featureId']
                            );
                        }

                        return this.includedSteps[journey[journey.pageId ? 'pageId' : 'featureId']];
                    });

                    return (
                        [
                            ...new Set(
                                filteredJourneys.map((journey) => journey[journey.pageId ? 'pageId' : 'featureId'])
                            )
                        ].length === numOfInclSteps
                    );
                });
                const percentFiltered = Math.round((filteredJourneyData.length / this.journeyData.length) * 100);
                this.$emit(
                    'updateFilteredPercentage',
                    `Displaying ${filteredJourneyData.length} of ${this.journeyData.length} (${percentFiltered}%) of completed journeys with filter applied`
                );

                return filteredJourneyData;
            }
            this.$emit('updateFilteredPercentage', '');

            return this.journeyData ? [...this.journeyData] : [];
        }
    },
    watch: {
        journeyData () {
            this.currentTab = 'topJourneys';
        },
        completedJourneysCount () {
            this.updating = true;
            // This timeout is what temporarily shows the loading indicator
            setTimeout(() => {
                this.updating = false;
            }, 500);
        }
    },
    methods: {
        handleSortSelection (value) {
            this.selectedSort = value;
        }
    }
};
</script>
<style lang="scss" scoped>
.journeys-card {
    &__tabs {
        background-color: #f8f8f9;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep .pendo-radio-group {
            width: 300px;
        }

        &--filter {
            position: absolute;
            right: 0;
            padding-right: 16px;
        }
    }

    ::v-deep .pendo-card {
        &__body {
            padding: 0;
        }
    }

    ::v-deep .pendo-loading {
        padding: 120px;
    }
}

.sort-select--label {
    color: $gray-primary;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .pendo-icon {
        margin-right: 0.5em;
    }
}
</style>
