<template>
    <ul class="poll-details">
        <li>
            <div class="poll-details__label">
                App
            </div>
            <pendo-app-display :apps="stepApp" />
        </li>
        <li>
            <div class="poll-details__label">
                Poll Type
            </div>
            <div>{{ pollType }}</div>
        </li>
        <li>
            <div class="poll-details__label">
                Poll Step
            </div>
            <div>{{ stepNumber }}</div>
        </li>
        <li v-if="showResponseCount">
            <div class="poll-details__label">
                Responses
            </div>
            <div v-if="loadingResponseCount">
                <pendo-loading-indicator
                    type="skeleton"
                    :skeleton-props="{
                        height: 13,
                        width: 50
                    }" />
            </div>
            <div v-if="!loadingResponseCount">
                {{ responseCount }}
            </div>
        </li>
        <li v-if="showResponseCount">
            <div class="poll-details__label">
                Unique Responses
            </div>
            <div v-if="!uniqueResponsesLabel">
                <pendo-loading-indicator
                    type="skeleton"
                    :skeleton-props="{
                        height: 13,
                        width: 50
                    }" />
            </div>
            <div v-if="uniqueResponsesLabel">
                {{ uniqueResponsesLabel }}
                <span
                    v-if="invalidResponsesLabel"
                    class="invalid-responses-label">
                    <pendo-icon
                        type="alert-triangle"
                        class="invalid-responses-label--icon"
                        display="inline"
                        size="18" />
                    {{ invalidResponsesLabel }}
                    <a
                        target="_blank"
                        rel="noopener"
                        href="https://support.pendo.io/hc/en-us/articles/360032573652-Troubleshoot-Poll-Error-Messages-Legacy-">
                        Details
                    </a>
                </span>
            </div>
        </li>
        <li>
            <div class="poll-details__label">
                Visitors Seen
            </div>
            <div v-if="loadingVisitorsSeen">
                <pendo-loading-indicator
                    type="skeleton"
                    :skeleton-props="{
                        height: 13,
                        width: 50
                    }" />
            </div>
            <div v-if="!loadingVisitorsSeen">
                {{ visitorCount }}
            </div>
        </li>
    </ul>
</template>

<script>
import get from 'lodash/get';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { PendoAppDisplay, PendoIcon, PendoLoadingIndicator } from '@pendo/components';
import isInteger from 'lodash/isInteger';

export default {
    name: 'PollDetails',
    components: {
        PendoAppDisplay,
        PendoIcon,
        PendoLoadingIndicator
    },
    props: {
        poll: {
            type: Object,
            required: true
        },
        loadingVisitorCount: {
            type: Boolean,
            default: false
        },
        visitorCount: {
            type: [Number, String],
            default: '--'
        },
        showResponseCount: {
            type: Boolean,
            default: false
        },
        loadingResponseCount: {
            type: Boolean,
            default: false
        },
        responseCount: {
            type: [Number, String],
            default: '--'
        },
        loadingUniqueResponseCount: {
            type: Boolean,
            default: false
        },
        uniqueResponseCount: {
            type: [Number, String],
            default: '--'
        },
        invalidResponseCount: {
            type: [Number, String],
            default: '--'
        },
        apps: {
            type: Array,
            required: true
        },
        guide: {
            type: Object,
            required: true
        },
        step: {
            type: Object,
            default: null
        }
    },
    computed: {
        pollType () {
            return {
                FreeForm: 'Open Text',
                PickList: 'Multiple Choice',
                PositiveNegative: 'Yes / No',
                NumberScale: 'Number Scale',
                NPSRating: 'NPS Rating',
                NPSReason: 'NPS Reason'
            }[get(this.poll, 'attributes.type')];
        },
        uniqueResponsesLabel () {
            if (this.loadingUniqueResponseCount || this.loadingVisitorCount) return null;
            if (!isInteger(this.uniqueResponseCount) || !isInteger(this.visitorCount)) {
                return `${this.uniqueResponseCount}`;
            }

            const responseRate = Math.round((this.uniqueResponseCount / this.visitorCount || 0) * 100);

            return `${this.uniqueResponseCount} (${responseRate}% response rate)`;
        },
        invalidResponsesLabel () {
            if (!isInteger(this.invalidResponseCount) || !this.invalidResponseCount) return null;

            return `${this.invalidResponseCount} invalid response${this.invalidResponseCount === 1 ? '' : 's'}`;
        },
        stepApp () {
            const appId = isCrossApp(this.guide) ? get(this.step, 'appIds[0]', '') : this.guide.appId;

            return this.apps.find(({ id }) => id === appId);
        },
        loadingVisitorsSeen () {
            return this.step && this.loadingVisitorCount;
        },
        stepNumber () {
            const index = this.guide.steps.indexOf(this.step);

            return index === -1 ? '--' : index + 1;
        }
    }
};
</script>

<style lang="scss">
.poll-details {
    margin: 0;
    padding: 8px 16px;
    list-style: none;

    > li {
        border-bottom: 1px solid $gray-lighter-5;

        > * {
            display: inline-block;
            padding: 9px 0;
        }

        > .poll-details__label {
            width: 145px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .pendo-app-display {
        padding: 0px;
        vertical-align: middle;
    }

    .invalid-responses-label--icon {
        margin-left: 10px;
    }
}
</style>
