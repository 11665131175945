import * as Agg2 from '@pendo/aggregations';
import { buildCorrectedStepEventPipeline } from './utils.js';
import { request, requestCsv, parseSegmentIdForAggregation, identifiedState } from '@/utils/aggregations';

/* eslint-disable id-length */
const { operators: o, modifiers: m, common: c } = Agg2;

export function getGuideActivityTableData (type, aggParams) {
    let spec;
    switch (type) {
        case 'byEvent':
            spec = buildGuideActivityTableDataByEventSpec(aggParams);
            break;
        case 'byVisitor':
        default:
            spec = buildGuideActivityTableDataByVisitorSpec(aggParams);
    }

    return request(spec);
}

export function getGuideActivityTableCsv (type, aggParams) {
    let spec;
    switch (type) {
        case 'byEvent':
            spec = buildGuideActivityTableDataByEventSpec(aggParams);
            break;
        case 'byVisitor':
        default:
            spec = buildGuideActivityTableDataByVisitorSpec(aggParams);
    }

    const fields = aggParams.columns.map((column) => {
        const field = {};
        field.label = column.csvLabel || column.label;
        field.field = column.prop;

        return field;
    });

    return requestCsv(spec, fields);
}

export function buildGuideActivityTableDataByVisitorSpec (aggParams) {
    let {
        columns,
        appId,
        timeSeries,
        segmentId,
        guideId,
        guideStepId,
        lastStepId,
        sortValue,
        isCsv,
        limit = isCsv ? undefined : 10000,
        isWalkthrough,
        addedColumns = [],
        keys,
        selectedFields,
        activityFilterId,
        includeMultiApp
    } = aggParams;

    if (isWalkthrough) {
        guideStepId = undefined;
    }
    aggParams.dateRange = timeSeries; // column generators rely on `dateRange`
    // need to call modifiers on only the columns due to the existence of a merge operator that uses the guide source.
    let columnPipeline = m.appIdModifier(
        { pipeline: c.callColumnGeneratorsWithDefaults(addedColumns, aggParams, keys) },
        appId
    );
    columnPipeline = segmentId ? m.segmentModifier(columnPipeline, segmentId) : columnPipeline;
    const mappings = columns.reduce((acc, column) => {
        acc[column.prop] = column.prop;

        return acc;
    }, {});
    mappings.s = 's';
    const basePipeline = o.pipeline(
        o.sources.guideEvents({ appId, guideId, guideStepId, timeSeries }),
        o.filter('eventSubType(type) != "guideTimeout"'), // Supers should see guideTimeouts, but Adopt does not have a concept of Super
        o.filter('eventSubType(type) != "guideSnoozeCanceled"'),
        o.filter('eventSubType(type) != "guideNotSeen"'),
        o.evaluate({
            stepStatus: 'eventSubType(type)',
            hasCompletedGuide: `if("${lastStepId}" == guideStepId, true, false)`,
            isWalkthrough: `${!!isWalkthrough}`,
            isButton: 'if(eventSubType(type) == "guideActivity" && uiElementType == "BUTTON", true, false)',
            isDismiss:
                'if(eventSubType(type) == "guideActivity" && contains(uiElementId, "pendo-close-guide-"), true, false)',
            isLink: 'if(eventSubType(type) == "guideActivity" && uiElementType == "A", true, false)',
            isSwipeLeft: 'if(eventSubType(type) == "guideActivity" && uiElementType == "LEFT-SWIPER", true, false)',
            isSwipeRight: 'if(eventSubType(type) == "guideActivity" && uiElementType == "RIGHT-SWIPER", true, false)'
        }),
        {
            switch: {
                stepStatus: {
                    type: [
                        { 'value': isWalkthrough ? 'Advanced' : 'Action Taken', '==': 'guideAdvanced' },
                        { 'value': 'Displayed', '==': 'guideSeen' },
                        { 'value': 'Display Alert', '==': 'guideTimeout' },
                        { 'value': 'Closed', '==': 'guideDismissed' },
                        { 'value': 'Guide Snoozed', '==': 'guideSnoozed' }
                    ]
                }
            }
        },
        o.evaluate({
            stepStatus: 'if(isButton, "Button Clicked", stepStatus)'
        }),
        o.evaluate({
            stepStatus: 'if(isDismiss, "Close Button Clicked", stepStatus)'
        }),
        o.evaluate({
            stepStatus: 'if(isLink, "Link Clicked", stepStatus)'
        }),
        o.evaluate({
            stepStatus: 'if((stepStatus == "Closed" && isWalkthrough && hasCompletedGuide), "Completed", stepStatus)'
        }),
        o.evaluate({
            stepStatus: 'if(isSwipeLeft, "Swiped Left", stepStatus)'
        }),
        o.evaluate({
            stepStatus: 'if(isSwipeRight, "Swiped Right", stepStatus)'
        }),
        o.evaluate({ stepStatus: 'if(isNil(stepStatus), type, stepStatus)' }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        ...buildCorrectedStepEventPipeline(keys, { includeMultiApp, appId }),
        o.evaluate({
            stepStart: 's[0].browserTime',
            sequenceLength: 'len(s)'
        }),
        o.sort(['guideId', ...keys, 'stepStart']),
        o.group(keys, o.groupField('s', { concat: 's' }), o.groupField('numRows', o.sum('sequenceLength'))),
        o.evaluate({ s: 'reverse(s)' }),
        o.evaluate({
            totalDuration: 'sum(map(s,duration))'
        }),
        !isWalkthrough && o.evaluate({ numSeen: 'sum(map(s,if(eventSubType(type) == "guideSeen",1,0)))' }),
        isWalkthrough &&
            o.pipeline(
                o.unwind('s', { index: 'sIndex' }),
                o.evaluate({ guideId: 's.guideId', guideStepId: 's.guideStepId' }),
                o.merge(
                    ['guideId', 'guideStepId'],
                    o.mappings({ stepIndex: 'stepIndex' }),
                    o.pipeline(
                        o.sources.guides({ appId, includeMultiApp }),
                        o.unwind('steps', { index: 'stepIndex' }),
                        o.select({
                            guideId: 'steps.guideId',
                            guideStepId: 'steps.id',
                            stepIndex: 'stepIndex'
                        })
                    )
                ),
                o.filter('!isNil(stepIndex)'),
                o.evaluate({ 's.stepIndex': 'stepIndex + 1' }),
                o.group(
                    keys,
                    o.groupField('s', { list: 's' }),
                    o.groupField('stepsSeen', { list: 's.stepIndex' }),
                    o.groupField('numSeen', { max: 's.stepIndex' }),
                    o.groupField('totalDuration', { max: 'totalDuration' }),
                    o.groupField('numRows', { first: 'numRows' })
                ),
                o.evaluate({ stepsSeen: 'sortUnique(stepsSeen)' })
            ),
        o.evaluate({ stepStatus: 's[0].stepStatus', browserTime: 's[0].browserTime' })
    );

    const addColumnsSortAndLimit = [
        ...columnPipeline.pipeline,
        sortValue && o.sort(sortValue),
        limit && o.limit(limit),
        o.select(selectedFields)
    ];

    let aggPipeline;
    switch (activityFilterId) {
        case 'seen':
            aggPipeline = o.pipeline(o.spawn(basePipeline), ...addColumnsSortAndLimit);
            break;
        case 'notSeen':
            aggPipeline = o.pipeline(
                o.sources.visitors(),
                o.identified(identifiedState(segmentId)),
                o.segment(parseSegmentIdForAggregation(segmentId)),
                o.merge(['visitorId'], o.mappings(mappings), basePipeline),
                o.filter('isNull(stepStatus)'),
                ...addColumnsSortAndLimit
            );
            break;
        case 'everyone':
        default:
            aggPipeline = o.pipeline(
                o.sources.visitors(),
                o.identified(identifiedState(segmentId)),
                o.segment(parseSegmentIdForAggregation(segmentId)),
                o.merge(['visitorId'], o.mappings(mappings), basePipeline),
                ...addColumnsSortAndLimit
            );
    }

    return o.aggregation('guide-activity-table-data', aggPipeline);
}

export function buildGuideActivityTableDataByEventSpec (aggParams) {
    let {
        appId,
        timeSeries,
        segmentId,
        guideId,
        guideStepId,
        lastStepId,
        sortValue,
        isCsv,
        limit = isCsv ? undefined : 10000,
        isWalkthrough,
        addedColumns = [],
        keys,
        selectedFields,
        includeMultiApp
    } = aggParams;

    aggParams.dateRange = timeSeries; // column generators rely on `dateRange`
    if (isWalkthrough) {
        guideStepId = undefined;
    }

    let columnPipeline = m.appIdModifier(
        { pipeline: c.callColumnGeneratorsWithDefaults(addedColumns, aggParams, keys) },
        appId
    );
    // need to call modifiers on only the columns due to the existence of a merge operator that uses the guide source.
    columnPipeline = segmentId ? m.segmentModifier(columnPipeline, segmentId) : columnPipeline;

    return o.aggregation(
        'guide-activity-table-data',
        o.pipeline(
            o.sources.guideEvents({ appId, guideId, guideStepId, timeSeries }),
            o.filter('eventSubType(type) != "guideTimeout"'),
            o.filter('eventSubType(type) != "guideSnoozeCanceled"'),
            o.filter('eventSubType(type) != "guideNotSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            isWalkthrough &&
                o.merge(
                    ['guideId', 'guideStepId'],
                    o.mappings({ stepIndex: 'stepIndex' }),
                    o.pipeline(
                        o.sources.guides({ appId, includeMultiApp }),
                        o.unwind('steps', { index: 'stepIndex' }),
                        o.select({ guideId: 'steps.guideId', guideStepId: 'steps.id', stepIndex: 'stepIndex + 1' })
                    )
                ),
            includeMultiApp &&
                o.merge(
                    ['appId'],
                    o.mappings({
                        appName: 'appName'
                    }),
                    o.pipeline(
                        o.sources.apps({ appId }),
                        o.select({
                            appName: 'displayName',
                            appId: 'id'
                        })
                    )
                ),
            o.evaluate({
                stepStatus: 'eventSubType(type)',
                hasCompletedGuide: `if("${lastStepId}" == guideStepId, true, false)`,
                isWalkthrough: `${!!isWalkthrough}`,
                isButton: 'if(eventSubType(type) == "guideActivity" && uiElementType == "BUTTON", true, false)',
                isDismiss:
                    'if(eventSubType(type) == "guideActivity" && contains(uiElementId, "pendo-close-guide-"), true, false)',
                isLink: 'if(eventSubType(type) == "guideActivity" && uiElementType == "A", true, false)',
                isSwipeLeft: 'if(eventSubType(type) == "guideActivity" && uiElementType == "LEFT-SWIPER", true, false)',
                isSwipeRight:
                    'if(eventSubType(type) == "guideActivity" && uiElementType == "RIGHT-SWIPER", true, false)'
            }),
            {
                switch: {
                    stepStatus: {
                        stepStatus: [
                            { 'value': isWalkthrough ? 'Advanced' : 'Action Taken', '==': 'guideAdvanced' },
                            { 'value': 'Displayed', '==': 'guideSeen' },
                            { 'value': 'Display Alert', '==': 'guideTimeout' },
                            { 'value': 'Closed', '==': 'guideDismissed' },
                            { 'value': 'Guide Snoozed', '==': 'guideSnoozed' }
                        ]
                    }
                }
            },
            o.evaluate({
                stepStatus: 'if(isButton, "Button Clicked", stepStatus)'
            }),
            o.evaluate({
                stepStatus: 'if(isDismiss, "Close Button Clicked", stepStatus)'
            }),
            o.evaluate({
                stepStatus: 'if(isLink, "Link Clicked", stepStatus)'
            }),
            o.evaluate({
                stepStatus:
                    'if((stepStatus == "Closed" && isWalkthrough && hasCompletedGuide), "Completed", stepStatus)'
            }),
            o.evaluate({
                stepStatus: 'if(isSwipeLeft, "Swiped Left", stepStatus)'
            }),
            o.evaluate({
                stepStatus: 'if(isSwipeRight, "Swiped Right", stepStatus)'
            }),
            ...columnPipeline.pipeline,
            sortValue && o.sort(sortValue),
            limit && o.limit(limit),
            o.select(selectedFields)
        )
    );
}
