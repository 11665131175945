<template>
    <segment-builder
        :config="{
            appId: activeApp.id,
            userId: user.id,
            data: {
                appFirstVisit: firstVisit,
                apps: listAllForActiveSubscription,
                schemas: filteredSchemaList,
                guides: { map: guideMap, filteredList: () => guideList },
                rcModules: { map: rcModulesMap, filteredList: () => rcModulesList },
                draftResourceCenters: { map: draftResourceCenterMap, filteredList: () => draftResourceCenterList },
                pages: { map: pageMap, filteredList: () => pageList },
                features: { map: featureMap, filteredList: () => featureList },
                workflows: { map: workflowMap, filteredList: () => workflowsList },
                segments: { map: segmentsMap, filteredList: () => segmentsListByGroup.customList }
            },
            filters: { timeSeries: activeTimeSeries },
            pendoHttpWrapperConfig: http.defaults,
            usesMultiApp: usesMultiApp,
            hasAdoptCustomPageSegments: hasAdoptCustomPageSegments,
            guideTargeting: editingGuideSegment,
            hasResourceCenter: activeHasResourceCenter,
            hasWorkflow: activeHasAnalytics,
            workflowWithinLastDateRange: workflowWithinLastDateRange,
            showWorkflowRicherSegmentation: true,
            showAppicons: true,
            canShowAnonymous: canShowAnonymousVisitors
        }"
        :editing-segment="editingSegment"
        :is-saving="isSavingSegment"
        :is-visible="visible"
        :show-super-panel="isSuper"
        @save="onSegmentSave"
        @close="closeSegmentBuilder" />
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { http } from '@pendo/http';
import { SegmentBuilder } from '@pendo/segment-builder';
import { isSegmentAttachedToGuide, isValidSegmentInContext } from '@/utils/segments';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'AppSegmentBuilder',
    components: {
        SegmentBuilder
    },
    props: {
        editingSegment: {
            type: Object,
            default: null
        },
        limitForGuideUsage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isSavingSegment: false,
            http
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            segmentsMap: (state) => state.filters.segmentsMap,
            dateRange: (state) => state.filters.dateRange,
            guideLoading: (state) => state.guides.isFetching,
            guideMap: (state) => state.guides.map,
            pageLoading: (state) => state.pages.isFetching,
            pageMap: (state) => state.pages.map,
            featureLoading: (state) => state.features.isFetching,
            featureMap: (state) => state.features.map,
            segmentLoading: (state) => !state.filters.segmentsLoaded,
            workflowLoading: (state) => state.workflows.isFetching,
            workflowMap: (state) => state.workflows.map
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            activeGlobalSegment: 'filters/activeSegment',
            activeTimeSeries: 'filters/activeTimeSeries',
            schemaList: 'filters/schemaList',
            firstVisit: 'apps/firstVisit',
            guideList: 'guides/list',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag',
            rcModulesList: 'guides/rcModulesList',
            rcModulesMap: 'guides/rcModulesMap',
            draftResourceCenterMap: 'resourceCenter/draftResourceCenterMap',
            draftResourceCenterList: 'resourceCenter/draftResourceCenterList',
            pageList: 'pages/list',
            featureList: 'features/list',
            isSuperUser: 'auth/isSuper',
            activeApp: 'apps/active',
            activeSub: 'subscriptions/active',
            workflowsList: 'workflows/list',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter',
            activeHasAnalytics: 'subscriptions/activeHasAnalytics'
        }),
        filteredSchemaList () {
            return this.schemaList.filter((schema) => {
                switch (schema.id) {
                    case 'guide':
                    case 'guideElement':
                        return !!this.guideList.length;
                    case 'resourceCenter':
                        return !!this.draftResourceCenterList.length;
                    case 'workflow':
                        return !!this.workflowsList.length;
                    case 'feature':
                        return !!this.featureList.length;
                    case 'page':
                        return !!this.pageList.length;
                }

                return true;
            });
        },
        editingGuideSegment () {
            return this.limitForGuideUsage || isSegmentAttachedToGuide(this.editingSegment, this.guideMap);
        },
        visible () {
            return (
                (!isEmpty(this.guideMap) || !this.guideLoading) &&
                (!isEmpty(this.pageMap) || !this.pageLoading) &&
                (!isEmpty(this.featureMap) || !this.featureLoading) &&
                (!isEmpty(this.segmentsMap) || !this.segmentsLoading) &&
                (!isEmpty(this.workflowMap) || !this.workflowLoading)
            );
        },
        isSuper () {
            if (!this.isSuperUser) return false;

            return this.activeSub.name === 'pendo-internal';
        },
        hasAdoptCustomPageSegments () {
            return this.hasSegmentFlag('adoptCustomPageSegments');
        },
        canShowAnonymousVisitors () {
            return this.activeSubHasFlag('showAnonymous');
        },
        workflowWithinLastDateRange () {
            return this.dateRange.count >= 7 && this.dateRange.id !== 'custom' ? this.dateRange.count : 0;
        }
    },
    created () {
        this.fetchSegments();
        this.fetchVisitorSchema({ forceRefresh: false });
        this.fetchPages();
        this.fetchGuides();
        this.fetchAllResourceCenters();
        this.fetchFeatures();
        this.fetchWorkflows();
    },
    methods: {
        ...mapActions({
            fetchSegments: 'filters/fetchSegments',
            fetchVisitorSchema: 'filters/fetchVisitorSchema',
            fetchPages: 'pages/fetch',
            fetchGuides: 'guides/fetch',
            fetchAllResourceCenters: 'resourceCenter/fetchAll',
            fetchFeatures: 'features/fetch',
            fetchWorkflows: 'workflows/fetch',
            updateSegment: 'filters/updateSegment'
        }),
        ...mapMutations({
            setNewSegment: 'filters/setNewSegment'
        }),
        async onSegmentSave ({ segment }) {
            const isValid = isValidSegmentInContext(segment, {
                guideMap: this.guideMap,
                usedForGuideTargeting: this.guideTargeting
            });
            if (!isValid) return;

            this.isSavingSegment = true;

            let finalSegment;

            if (segment.id) {
                await this.updateSegment({ segment });
                finalSegment = this.segmentsMap[segment.id];
            } else {
                const { data } = await http.post('/api/s/_SID_/segment', segment);
                this.setNewSegment({ segment: data });
                finalSegment = data;
            }

            this.closeSegmentBuilder();
            this.$emit('save', finalSegment);
            this.isSavingSegment = false;
        },
        closeSegmentBuilder () {
            this.$emit('close');
        }
    }
};
</script>
