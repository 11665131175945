<template>
    <pendo-card
        v-pendo-loading:feather="isFetchingCountableList"
        :title="cardTitle"
        class="countable-use-by-visitor">
        <template #filters>
            <pendo-tag
                :label="activeSegment.name"
                type="filter" />
            <pendo-tag
                :label="activeDateRange.label"
                type="filter" />
        </template>
        <div class="countable-use-by-visitor__chart">
            <pendo-empty-state
                v-if="!isFetchingCountableList && emptyState"
                :icon="emptyStateIcon"
                :title="emptyStateTitle"
                :description="emptyStateDescription"
                class="countable-use-by-visitor__empty" />
            <div
                :ref="chartRef"
                class="pendo-highcharts-container" />
        </div>
    </pendo-card>
</template>

<script>
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { PendoCard, PendoEmptyState, PendoLoading, PendoTag } from '@pendo/components';
import { pluralize } from '@/utils/formatters';
import { SCATTER_CHART_BASE, chartColors } from '@/utils/highcharts';

export default {
    name: 'CountableUseByVisitor',
    components: {
        PendoCard,
        PendoEmptyState,
        PendoTag
    },
    directives: {
        PendoLoading
    },
    props: {
        allApps: {
            type: Object,
            default: () => ({})
        },
        countableType: {
            type: String,
            required: true
        },
        countableList: {
            type: Array,
            default: () => []
        },
        isFetchingCountableList: {
            type: Boolean,
            default: false
        },
        showAppUsage: {
            type: Boolean,
            default: false
        },
        activeSegment: {
            type: Object,
            default: () => ({})
        },
        activeDateRange: {
            type: Object,
            default: () => ({})
        },
        engageChartColors: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null,
            chartConfig: null
        };
    },
    computed: {
        appIds () {
            return Object.keys(this.allApps);
        },
        cardTitle () {
            return `${capitalize(this.countableType)} Use By Visitor`;
        },
        emptyStateTitle () {
            return `${capitalize(this.countableType)}s not Found`;
        },
        emptyStateDescription () {
            switch (this.countableType) {
                case 'page':
                    return 'Try clearing filters. Pages without view data will not appear.';
                case 'feature':
                    return 'Try clearing filters. Features without click data will not appear.';
                default:
                    return 'Try clearing filters. Items without data will not appear.';
            }
        },
        emptyStateIcon () {
            return {
                'type': this.countableType,
                'size': 32,
                'stroke-width': 1.5
            };
        },
        chartRef () {
            return `${this.countableType}UseByVisitor`;
        },
        seriesData () {
            const seriesData = {};
            this.countableList.forEach((listItem) => {
                const { app, displayName, numVisitors } = listItem;
                const numEvents = this.countableType === 'page' ? listItem.pageLoads : listItem.numEvents;

                if (app && !seriesData[app.id]) {
                    seriesData[app.id] = {
                        data: [],
                        name: app.displayName,
                        marker: {
                            symbol: 'circle'
                        },
                        showInLegend: this.showAppUsage
                    };
                    if (this.showAppUsage) {
                        const { color } = app;
                        seriesData[app.id].color =
                            color ||
                            this.platformChartColors[
                                this.appIds.indexOf(app.id.toString()) % this.platformChartColors.length
                            ];
                    }
                }
                const name = displayName;
                const x = numVisitors;
                const y = Math.round(numEvents / numVisitors);
                if (app && x > 0) seriesData[app.id].data.push({ name, x, y });
            });

            return Object.values(seriesData);
        },
        emptyState () {
            if (!this.countableList.length) {
                return true;
            }
            const oneCountableWithData = this.seriesData.some((serie) =>
                get(serie, 'data', []).some((data) => !isNaN(data.y) && data.y > 0)
            );

            return !oneCountableWithData;
        },
        platformChartColors () {
            if (this.engageChartColors.length) {
                return this.engageChartColors;
            }

            return chartColors;
        }
    },
    watch: {
        countableList: {
            handler () {
                if (this.isFetchingCountableList || !this.chart) {
                    return;
                }

                const diff = this.chart.series.length - this.seriesData.length;
                if (diff > 0) {
                    for (let i = this.chart.series.length; i > this.seriesData.length; i--) {
                        this.chart.series[i - 1].remove(true);
                    }
                } else if (diff < 0) {
                    for (let i = this.chart.series.length; i < this.seriesData.length; i++) {
                        this.chart.addSeries({});
                    }
                }

                this.chart.update({
                    series: this.seriesData
                });
            },
            deep: true
        }
    },
    mounted () {
        this.chartConfig = this.getChartConfig();
        this.chart = this.$pendo.highcharts.chart(this.$refs[this.chartRef], this.chartConfig);
    },
    methods: {
        getChartConfig () {
            const config = { ...SCATTER_CHART_BASE };

            config.series = this.seriesData;
            config.chart.height = 316;
            config.tooltip.pointFormatter = function () {
                const { name, x, y } = this;

                return `<label>${name}</label><br/>Used by <b>${x}</b> ${pluralize(
                    'Visitor',
                    x
                )}<br/><b>${y}</b> ${pluralize('View', y)} per Visitor`;
            };
            config.yAxis[0].title.text = 'Average Views Per Visitor';
            config.xAxis[0].title.text = 'Number of Visitors';

            return config;
        }
    }
};
</script>
<style lang="scss">
.countable-use-by-visitor {
    .countable-use-by-visitor__chart {
        position: relative;
    }

    .countable-use-by-visitor__empty {
        z-index: 1;
    }
}
</style>
