/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { request, parseSegmentIdForAggregation } from '@/utils/aggregations';
import { buildCorrectedStepEventPipeline } from './utils.js';

export function getTimeOnGuide ({ appIds, guideId, segmentId, timeSeries, isMultiApp }) {
    const spec = buildTimeOnGuideSpec({ appIds, guideId, segmentId, timeSeries, isMultiApp });

    return request(spec);
}

export function getTimeOnStep ({ appIds, guideId, segmentId, timeSeries, ranges, isMultiApp }) {
    const spec = buildTimeOnStepSpec({ appIds, guideId, segmentId, timeSeries, ranges, isMultiApp });

    return request(spec);
}

export function buildTimeOnGuideSpec ({ appIds, guideId, segmentId, timeSeries, isMultiApp }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'time-on-guide',
        o.pipeline(
            ...buildTimeSetUp({ appIds, guideId, timeSeries, segmentId, isMultiApp }),
            o.fork(
                o.pipeline(
                    o.filter('!isNil(duration) && duration != 0'),
                    o.group(
                        ['guideId', 'guideStepId'],
                        o.groupField('medianDuration', {
                            median: 'duration'
                        })
                    )
                ),
                o.pipeline(
                    o.evaluate({
                        duration: 'if(isNil(duration), 0, duration)'
                    }),
                    o.group(
                        ['guideId', 'guideStepId'],
                        o.groupField('numDurations', o.countIf(null, 'duration != 0')),
                        o.groupField('sum', o.sum('duration'))
                    ),
                    o.evaluate({
                        averageDuration: 'if(numDurations == 0, 0, sum / numDurations)'
                    })
                )
            ),
            o.join('guideStepId'),
            o.fork(
                o.pipeline(
                    o.group(['guideId'], o.groupField('averageDuration', o.sum('averageDuration'))),
                    o.select[('guideId', 'averageDuration')]
                ),
                o.pipeline(o.select(['guideStepId', 'averageDuration', 'medianDuration']))
            )
        )
    );
}

export function buildTimeOnStepSpec ({ appIds, guideId, segmentId, timeSeries, ranges, isMultiApp }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'time-on-step',
        o.pipeline(
            ...buildTimeSetUp({ appIds, guideId, timeSeries, segmentId, isMultiApp }),
            o.fork(
                o.pipeline(
                    o.filter('!isNil(duration) && duration != 0'),
                    o.switchStatement(
                        'duration',
                        'duration',
                        ...ranges.map((range) => {
                            const caseStatement = {
                                'value': range.duration,
                                '>=': range.start
                            };

                            if (range.end) {
                                caseStatement['<'] = range.end;
                            }

                            return caseStatement;
                        })
                    ),
                    o.group(['guideStepId', 'duration'], o.groupField('total', o.count(null)))
                ),
                o.pipeline(
                    o.filter('!isNil(duration) && duration != 0'),
                    o.group(
                        ['guideId'],
                        o.groupField('medianDuration', {
                            median: 'duration'
                        })
                    )
                ),
                o.pipeline(
                    o.evaluate({
                        duration: 'if(isNil(duration), 0, duration)'
                    }),
                    o.group(
                        ['guideId', 'guideStepId'],
                        o.groupField('numDurations', o.countIf(null, 'duration != 0')),
                        o.groupField('sum', o.sum('duration'))
                    ),
                    o.evaluate({
                        averageDuration: 'if(numDurations == 0, 0, sum / numDurations)'
                    }),
                    o.select(['guideId', 'averageDuration'])
                )
            )
        )
    );
}

function buildTimeSetUp ({ appIds, guideId, timeSeries, segmentId, isMultiApp }) {
    const { operators: o } = Agg2;

    return o.pipeline(
        o.sources.guideEvents({
            appId: appIds,
            guideId,
            timeSeries
        }),
        o.filter('eventSubType(type) != "guideSnoozeCanceled"'),
        o.filter('eventSubType(type) != "guideNotSeen"'),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.select(['visitorId', 'type', 'browserTime', 'guideId', 'guideStepId']),
        ...buildCorrectedStepEventPipeline(['visitorId'], { isMultiApp, appId: appIds }),
        o.unwind('s'),
        o.select({
            visitorId: 'visitorId',
            guideId: 'guideId',
            guideStepId: 's.guideStepId',
            duration: 's.duration',
            type: 's.type'
        })
    );
}
