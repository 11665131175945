import * as Agg2 from '@pendo/aggregations';
import { request, parseSegmentIdForAggregation, identifiedState } from '@/utils/aggregations';
import { requestVisitorOrAccountIdCsv } from './utils';

export function getVisitorsPerStep ({ appIds, guideId, timeSeries, segmentId }) {
    const spec = buildVisitorsPerStepSpec({ appIds, guideId, timeSeries, segmentId });

    return request(spec);
}

export function buildVisitorsPerStepSpec ({ appIds, guideId, timeSeries, segmentId }) {
    const { operators: o, common: c } = Agg2;

    return o.aggregation(
        'visitors-per-step',
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, timeSeries, guideId }),
            o.filter('type=="guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                'guideStepId',
                ...c.groupFields({
                    visitorCount: o.count('visitorId')
                })
            )
        )
    );
}

export function getStepsSeenVisitorsAndAccounts ({ appIds, guideId, guideStepId, timeSeries, segmentId }) {
    const spec = buildStepsSeenVisitorsAndAccountsSpec({ appIds, guideId, guideStepId, timeSeries, segmentId });

    return request(spec);
}

export function buildStepsSeenVisitorsAndAccountsSpec ({ appIds, guideId, guideStepId, timeSeries, segmentId }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        'steps-seen-visitors-and-accounts',
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, timeSeries, guideId, guideStepId }),
            o.filter('type=="guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.fork(
                o.pipeline(
                    o.group('visitorId'),
                    o.sort('visitorId'),
                    o.filter('!isNull(visitorId) && visitorId!=""'),
                    o.evalExpression({
                        'visitor.id': 'visitorId'
                    }),
                    o.reduce({
                        visitors: o.list('visitor')
                    })
                ),
                o.pipeline(
                    o.group('accountId'),
                    o.sort('accountId'),
                    o.filter('!isNull(accountId) && accountId!=""'),
                    o.evalExpression({
                        'account.id': 'accountId'
                    }),
                    o.reduce({
                        accounts: o.list('account')
                    })
                )
            ),
            o.join()
        )
    );
}

export async function getStepsSeenVisitorsAndAccountsCsv ({
    appIds,
    guideId,
    guideStepId,
    timeSeries,
    segmentId,
    kind
}) {
    const spec = buildStepsSeenVisitorsAndAccountsCsvSpec({
        appIds,
        guideId,
        guideStepId,
        timeSeries,
        segmentId,
        kind
    });

    return requestVisitorOrAccountIdCsv(spec, kind);
}

export function buildStepsSeenVisitorsAndAccountsCsvSpec ({
    appIds,
    guideId,
    guideStepId,
    timeSeries,
    segmentId,
    kind
}) {
    const { operators: o } = Agg2;

    const select = {
        visitor: {
            id: 'visitorId'
        },
        account: {
            id: 'accountId'
        }
    }[kind];

    return o.aggregation(
        'steps-seen-visitors-and-accounts-csv',
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, timeSeries, guideId, guideStepId }),
            o.filter('type=="guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(`${kind}Id`),
            o.sort(`${kind}Id`),
            o.filter(`!isNull(${kind}Id) && ${kind}Id!=""`),
            o.select(select)
        )
    );
}
