<template>
    <pendo-drawer
        size="fullscreen"
        title="Guide creation with Pendo AI"
        :visible="visible"
        @close="handleClose">
        <template #body>
            <div class="guide-creation-body">
                <div class="guide-creation-selection">
                    <prompt-settings-with-a-i
                        :apps="apps"
                        :app-alias="appAlias"
                        :selected-app="selectedApp"
                        :url="url"
                        :lookaside-host="lookasideHost"
                        :is-pendo-user="isPendoUser"
                        @promptSettingsValidated="onPromptSettingsValidated"
                        @urlUpdated="onUrlUpdated"
                        @lookasideHostUpdated="lookasideHost = $event"
                        @appSelected="onAppSelected"
                        @appAliasUpdated="onAppAliasUpdated" />
                    <guide-details-with-a-i
                        :categories="categories"
                        :selected-theme="selectedTheme"
                        :themes="themes"
                        :app-id="selectedApp.id"
                        @guideNameUpdated="onGuideNameUpdated"
                        @themeSelected="onThemeSelected"
                        @categorySelected="onCategorySelected" />
                </div>
                <guide-creation-with-a-i-panel
                    class="guide-creation-with-ai-panel"
                    :is-loading="isSavingGuide"
                    :is-generating-steps="isGeneratingSteps"
                    :is-regenerating-step="isRegeneratingStep"
                    :generated-steps="generatedSteps"
                    :application="selectedApp"
                    :prompt="stepGenerationPrompt"
                    @updatePrompt="stepGenerationPrompt = $event"
                    @generateSteps="$emit('generateSteps', $event)"
                    @regenerateStep="regenerateStep"
                    @updateSteps="$emit('updateSteps', $event)" />
            </div>
        </template>
        <template #footer>
            <div class="guide-creation-footer">
                <pendo-button
                    :disabled="!canSaveAndEdit"
                    class="guide-creation-save-and-edit-button"
                    label="Save and edit in designer"
                    type="secondary"
                    :loading="isSavingGuide"
                    @click="createGuideAndLaunchBuilder" />
                <pendo-button
                    :disabled="!canSave"
                    class="guide-creation-save-draft-button"
                    label="Save draft"
                    type="primary"
                    :loading="isSavingGuide"
                    @click="createGuide" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import { PendoDrawer, PendoButton } from '@pendo/components';
import { EDITOR_TYPES } from '@pendo/services/Constants';
import { generateVDSLaunchParams, generateAdoptStudioLaunchParams } from '@/stateless-components/utils/designer';
import PromptSettingsWithAI from './PromptSettingsWithAI.vue';
import GuideDetailsWithAI from './GuideDetailsWithAI.vue';
import GuideCreationWithAIPanel from './GuideCreationWithAIPanel.vue';

export default {
    name: 'GuideCreationDrawerWithAI',
    components: {
        PendoDrawer,
        PendoButton,
        PromptSettingsWithAI,
        GuideDetailsWithAI,
        GuideCreationWithAIPanel
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        categories: {
            type: Array,
            default: () => []
        },
        appAlias: {
            type: String,
            default: ''
        },
        selectedApp: {
            type: Object,
            required: true
        },
        selectedTheme: {
            type: Object,
            default: () => null
        },
        apps: {
            type: Array,
            default: () => []
        },
        isSavingGuide: {
            type: Boolean,
            default: false
        },
        isGeneratingSteps: {
            type: Boolean,
            default: false
        },
        isRegeneratingStep: {
            type: Boolean,
            default: false
        },
        generatedSteps: {
            type: Array,
            default: () => []
        },
        themes: {
            type: Array,
            default: () => []
        },
        isPendoUser: {
            type: Boolean,
            default: false
        },
        launchGuideInVds: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            selectedName: null,
            guideCategory: null,
            stepGenerationPrompt: null,
            lookasideHost: null,
            arePromptSettingsValid: false
        };
    },
    computed: {
        canSave () {
            return (
                !this.isGeneratingSteps && !this.isRegeneratingStep && this.selectedApp && this.generatedSteps.length
            );
        },
        canSaveAndEdit () {
            return this.canSave && this.arePromptSettingsValid && this.url;
        },
        trackEventProps () {
            return {
                prompt: this.stepGenerationPrompt.description,
                steps: this.generatedSteps.length,
                tone: this.stepGenerationPrompt.tone,
                appId: this.selectedApp.id,
                appName: this.selectedApp.name,
                appAlias: this.appAlias
            };
        }
    },
    methods: {
        handleClose () {
            this.selectedName = null;
            this.stepGenerationPrompt = null;
            this.lookasideHost = null;
            this.$emit('close');
            this.$emit('updateSteps', []);
            this.$emit('appAliasUpdated', '');
        },
        regenerateStep ({ index }) {
            this.$emit('regenerateStep', { index, tone: this.stepGenerationPrompt.tone, appId: this.selectedApp.id });
        },
        onAppSelected (app) {
            this.$emit('appSelected', app);
        },
        onAppAliasUpdated (alias) {
            this.$emit('appAliasUpdated', alias);
        },
        onGuideNameUpdated (name) {
            this.selectedName = name;
        },
        onThemeSelected (theme) {
            this.$emit('themeSelected', theme);
        },
        onCategorySelected (category) {
            this.$emit('guideCategorySelected', category);
        },
        onUrlUpdated (url) {
            this.$emit('urlUpdated', url);
        },
        onPromptSettingsValidated (isValid) {
            this.arePromptSettingsValid = isValid;
        },
        createGuide () {
            const layout = {
                name: this.selectedName,
                empty: false,
                buildingBlocks: this.generatedSteps?.map(({ buildingBlocks }) => buildingBlocks)
            };

            const config = {
                layout,
                appId: this.selectedApp.id,
                themeId: this.selectedTheme?.id,
                editorType: this.launchGuideInVds ? EDITOR_TYPES.VDS : EDITOR_TYPES.ADOPT_STUDIO,
                trackEventProps: this.trackEventProps
            };

            this.$emit('createGuide', config);
        },
        async createGuideAndLaunchBuilder () {
            const layout = {
                name: this.selectedName,
                empty: false,
                badge: false,
                confirmation: false,
                buildingBlocks: this.generatedSteps?.map(({ buildingBlocks }) => buildingBlocks)
            };
            let launchParams = {
                target: 'latest'
            };
            if (this.lookasideHost) {
                launchParams = this.launchGuideInVds
                    ? generateVDSLaunchParams(this.lookasideHost)
                    : generateAdoptStudioLaunchParams(this.lookasideHost);
            }
            const token = {
                forceNativeDesigner: this.selectedApp.platform === 'web',
                idForApp: this.selectedApp.id,
                type: 'guide',
                host: this.lookasideHost ? `https://${this.lookasideHost}` : window.location.origin,
                adoptv2: true,
                DADesigner: !this.launchGuideInVds,
                ...launchParams
            };

            const config = {
                layout,
                appId: this.selectedApp.id,
                themeId: this.selectedTheme?.id,
                url: this.url,
                token,
                editorType: this.launchGuideInVds ? EDITOR_TYPES.VDS : EDITOR_TYPES.ADOPT_STUDIO,
                trackEventProps: this.trackEventProps
            };

            this.$emit('createGuide', config);
        }
    }
};
</script>

<style>
.guide-creation-body {
    display: flex;
    gap: 15px;
    padding: 25px;
}

.guide-creation-selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 15px;
}

.guide-creation-with-ai-panel {
    flex: 3;
}

.guide-creation-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
